import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { VendersContext } from "../../context/Store";
import { BASE_URL } from "../../context/Enviroment";
import { Dropdown } from "primereact/dropdown";
import Swal from "sweetalert2";

export default function SuggestedEditsDetails() {
  const [editsData, setEditsData] = useState({});
  const { isLang } = useContext(VendersContext);
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [ingredientsText, setIngredientsText] = useState("");
  const [ingredientsLocale, setIngredientsLocale] = useState("de");
  const navigate = useNavigate();

  useEffect(
    () => {
      const fetchEditDetails = async () => {
        const token = localStorage.getItem("userToken");
        try {
          const response = await axios.get(`${BASE_URL}/dashboard/suggest-edits/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setEditsData(response.data.data);
          setStatus(response.data.data.status);
          setIngredientsText(response.data.data.ingredients);
        } catch (error) {
          console.error("There was an error fetching the edits details", error);
        }
      };

      fetchEditDetails();
    },
    [id]
  );

  // Function to remove an image
  const removeImage = imageKey => {
    const newData = { ...editsData, [imageKey]: null };
    setEditsData(newData);
  };

  const handleAcceptImages = async () => {
    const acceptedImageTypes = Object.keys(editsData).reduce((acc, key) => {
      if (key.startsWith("image_") && editsData[key]) {
        const type = key
          .split("_")
          .slice(1)
          .join("_");
        acc.push(type);
      }
      return acc;
    }, []);

    const payload = { accept_images: acceptedImageTypes };

    try {
      const response = await axios.post(`${BASE_URL}/dashboard/suggest-edits/accept-images/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      });
      //console.log("Images accepted:", response.data);
      // Show success alert
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Images accepted successfully!"
      });
    } catch (error) {
      console.error("Error accepting images", error);
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to accept images. Please try again later."
      });
    }
  };

  // handle status
  const handleStatusChange = event => {
    setStatus(event.target.value);
  };

  const handleSubmitStatus = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/suggest-edits/accept-status/${id}`,
        { accept_status: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`
          }
        }
      );
      //console.log("Status updated successfully:", response.data);
      // Show success alert
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Status updated successfully!"
      });
    } catch (error) {
      console.error("Failed to update status:", error);
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update status. Please try again later."
      });
    }
  };

  //handle ingrediants

  const handleIngredientsChange = event => {
    setIngredientsText(event.target.value);
  };

  const handleLocaleChange = event => {
    setIngredientsLocale(event.target.value);
  };

  const handleSubmitIngredients = async () => {
    try {
      const payload = {
        accept_ingredients: ingredientsText,
        ingredients_locale: ingredientsLocale
      };
      const response = await axios.post(`${BASE_URL}/dashboard/suggest-edits/accept-ingredients/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      });
      //console.log("Ingredients updated successfully:", response.data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Ingredients updated successfully!"
      });
    } catch (error) {
      console.error("Failed to update ingredients:", error);
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update ingredients. Please try again later."
      });
    }
  };
  const languages = [
    { label: isLang === "en" ? "Arabic" : "اللغة العربية", value: "ar" },
    { label: isLang === "en" ? "English" : "اللغة الانجليزية", value: "en" },
    { label: isLang === "en" ? "German" : "اللغة الالمانية", value: "de" }
  ];

  //handle delete product
  const handleDeleteData = async () => {
    try {
      await axios.delete(`${BASE_URL}/dashboard/suggest-edits/delete/${editsData.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      });
      //console.log("Data deleted successfully");
      // Show success alert
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Data deleted successfully!"
      });
      navigate("/suggested-edits");
    } catch (error) {
      console.error("Error deleting data:", error);
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete data. Please try again later."
      });
    }
  };
  return (
    <div className="container">
      {/* status card */}
      <div className="d-flex flex-row gap-1">
        <div className="col-md-8 card p-3 m-4">
          <div>
            <label htmlFor="ingredients_text_en">{isLang === "en" ? "Ingredients" : "المكونات:"}</label>
            <textarea className="w-100" id="ingredients_text_en" value={ingredientsText} onChange={handleIngredientsChange} />
          </div>

          {/* Dropdown for language selection */}
          <div className="mt-3  gap-2 w-100 align-items-center">
            <label htmlFor="ingredients_locale">{isLang === "en" ? "Language" : "اللغة: "}</label>

            <Dropdown
              value={ingredientsLocale}
              options={languages}
              onChange={handleLocaleChange}
              placeholder="Select a Language"
              id="ingredients_locale"
              className="w-100"
            />
          </div>
          <div className="d-flex gap-3 mt-3 flex-row-reverse">
            <Button rounded text raised className="rounded-4" label={isLang === "en" ? "Save Changes" : " حفظ التعديلات"} icon="pi pi-check" onClick={handleSubmitIngredients} />
          </div>
        </div>

        <div className="col-md-3 card p-3 m-4">
          <h6 htmlFor="status" className="mb-3">
            {isLang === "en" ? "Status" : "الحالة "}
          </h6>
          <div className="d-flex flex-column gap-5">
            <div className="product-status d-flex justify-content-around">
              {/* MUSHBOOH */}
              <input
                type="radio"
                className="btn-check"
                name="status"
                id="MUSHBOOH-outlined"
                value="MUSHBOOH"
                checked={status === "MUSHBOOH"}
                onChange={handleStatusChange}
                autoComplete="off"
              />
              <label className="btn btn-outline-warning status-label" htmlFor="MUSHBOOH-outlined">
                {isLang === "en" ? "MUSHBOOH" : "مشبوه"}
              </label>

              {/* HALAL */}
              <input
                type="radio"
                className="btn-check"
                name="status"
                id="HALAL-outlined"
                value="HALAL"
                checked={status === "HALAL"}
                onChange={handleStatusChange}
                autoComplete="off"
              />
              <label className="btn btn-outline-success status-label" htmlFor="HALAL-outlined">
                {isLang === "en" ? "HALAL" : " حلال"}
              </label>

              {/* HARAM */}
              <input
                type="radio"
                className="btn-check"
                name="status"
                id="HARAM-outlined"
                value="HARAM"
                checked={status === "HARAM"}
                onChange={handleStatusChange}
                autoComplete="off"
              />
              <label className="btn btn-outline-danger status-label" htmlFor="HARAM-outlined">
                {isLang === "en" ? "HARAM" : "حرام"}
              </label>
            </div>

            <div className="d-flex gap-3 mt-3 justify-content-center">
              <Button rounded text raised className="rounded-4" label={isLang === "en" ? "Save Changes" : " حفظ التعديلات"} icon="pi pi-check" onClick={handleSubmitStatus} />
            </div>
          </div>
        </div>
      </div>

      <div className="card p-3 m-4">
        {/* Main Image */}
        <div className="d-flex flex-row justify-content-between align-items-center mb-4">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center justify-content-center mb-2">
            <h4 className="mb-2">{isLang === "en" ? "Main Image:" : "الصورة الاساسية:"}</h4>
            {editsData.image_front && (
              <>
                <div className="d-flex flex-row gap-4 align-items-center">
                  <Image src={editsData.image_front} alt="Front" width="90px" height="70px" preview />
                  <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    className="p-button-rounded p-button-danger"
                    onClick={() => removeImage("image_front")}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Ingredients Image */}
        <div className="d-flex flex-row justify-content-between align-items-center mb-4">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center justify-content-center mb-2">
            <h4 className="mb-2">{isLang === "en" ? "Ingredients Image:" : "صورة المكونات:"}</h4>
            {editsData.image_ingredients && (
              <>
                <div className="d-flex flex-row gap-4 align-items-center">
                  <Image src={editsData.image_ingredients} alt="Ingredients" width="90px" height="70px" preview />
                  <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    className="p-button-rounded p-button-danger"
                    onClick={() => removeImage("image_ingredients")}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Nutrition Facts Image */}
        <div className="d-flex flex-row justify-content-between align-items-center mb-4">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center justify-content-center mb-2">
            <h4 className="mb-2">{isLang === "en" ? "Nutrition Facts Image:" : "صورة التغذية:"}</h4>
            {editsData.image_nutrition_facts && (
              <>
                <div className="d-flex flex-row gap-4 align-items-center">
                  <Image src={editsData.image_nutrition_facts} alt="Nutrition Facts" width="90px" height="70px" preview />
                  <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    className="p-button-rounded p-button-danger"
                    onClick={() => removeImage("image_nutrition_facts")}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* More Interesting Image */}
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center justify-content-center">
            <h4 className="mb-2">{isLang === "en" ? "More Interesting Image:" : "صورة اضافية:"}</h4>
            {editsData.image_more_interesting && (
              <div className="d-flex flex-row gap-4 align-items-center">
                <Image src={editsData.image_more_interesting} alt="More Interesting" width="90px" height="70px" preview />
                <Button
                  icon="pi pi-trash"
                  rounded
                  outlined
                  severity="danger"
                  className="p-button-rounded p-button-danger"
                  onClick={() => removeImage("image_more_interesting")}
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex gap-3 mt-3 text-center" style={{ alignSelf: "center" }}>
          <Button rounded text raised className="rounded-4" icon="pi pi-check" label={isLang === "en" ? "Save Changes" : " حفظ التعديلات"} onClick={handleAcceptImages} />
        </div>
      </div>
      <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
        <div className=" d-flex flex-wrap gap-3 px-3 ">
          <Button
            label={isLang === "en" ? "Delete" : "حذف"}
            icon="pi pi-trash"
            severity="danger"
            className="rounded-3 d-flex flex-row gap-1"
            onClick={handleDeleteData}
          />
        </div>
      </div>
    </div>
  );
}
